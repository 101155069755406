
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


























@include mq('l') {
  .flexible-section {
    display: flex;
  }

  .flexible-section__intro {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: col(3.5, 10);
    margin-right: col(1, 10);
  }

  .flexible-section__content {
    width: col(5.5, 10);
  }
}

@include mq('xl') {
  .flexible-section__intro {
    width: col(3, 10);
  }

  .flexible-section__content {
    width: col(6, 10);
  }
}
